<template>
  <el-container class="login">
    <el-container class="top-container">
      <el-header class="header">
        <my-simple-header />
      </el-header>
      <!-- <el-aside class="aside" width="60%">
        <el-empty description="暂无描述"></el-empty>
      </el-aside> -->
      <el-main class="main">
        <div class="login-form-container light-shadow">
          <el-form
            :model="loginForm"
            :rules="loginFormRules"
            status-icon
            label-width="4rem"
            ref="loginFormDom"
            class="login-form"
            label-position="right"
            label-suffix=":"
            size="mini"
          >
            <el-form-item label="账号" prop="userName">
              <el-input
                type="text"
                v-model="loginForm.userName"
                @keypress.native="userNameInputEntry"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                ref="pwdInput"
                type="password"
                v-model="loginForm.password"
                @keypress.native="pwdInputEntry"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="记住登录" prop="remember" class="no-margin">
              <el-switch v-model="loginForm.remember"></el-switch>
            </el-form-item> -->
            <el-form-item class="no-margin">
              还没账号？<el-link type="primary" href="/register-tenant"
                >现在注册</el-link
              >
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                :loading="logining"
                class="login-btn"
                @click="doLogin"
                >登&nbsp;&nbsp;&nbsp;录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-main>
    </el-container>
    <el-footer class="footer center-flex"><my-beian /></el-footer>
  </el-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapMutations } = createNamespacedHelpers("userStore");
const { mapMutations: menuStoreMutations } =
  createNamespacedHelpers("menuStore");

export default {
  name: "Login",
  data() {
    return {
      logining: false,
      loginForm: {},
      loginFormRules: {
        userName: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
          {
            min: 6,
            max: 18,
            message: "账号格式不正确",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    userNameInputEntry() {
      if (event && event.charCode == 13) {
        this.$refs.pwdInput.focus();
      }
    },
    pwdInputEntry() {
      if (event && event.charCode == 13) {
        this.doLogin();
      }
    },
    async doLogin() {
      try {
        await this.$refs.loginFormDom.validate();
      } catch (e) {
        console.log(e);
        return;
      }
      this.logining = true;
      try {
        let userInfo = await this.$http.doApi("fw-cms-user-login", {
          loginId: this.loginForm.userName,
          password: this.loginForm.password,
        });
        this.setUserInfo(userInfo);

        let menuData = await this.$http.doApi("func-power-tree", {
          includeSub: true,
        });

        this.setMenus(menuData.rows);

        let bzhUrlData = await this.$http.doApi("fw-bzh-getBzhUrl");
        this.$http.setBzhUrl(bzhUrlData.val);

        this.$router.replace("/");
      } catch (e) {
        console.log(e);
      } finally {
        this.logining = false;
      }
    },
    ...mapMutations(["setUserInfo"]),
    ...menuStoreMutations(["setMenus"]),
  },
  components: {
    "my-beian": () => import("@/components/BeiAn"),
    "my-simple-header": () => import("@/components/SimpleHeader"),
  },
};
</script>

<style lang="less">
.login {
  height: 100%;

  .top-container {
    background: url("https://www.qiuxs.cn/sharing-tools/img/read/9fd317033069fe94588b13f7b093531d")
      center center;
    background-size: cover;

    .header {
      padding: 0px 15%;
      line-height: 60px;
      color: #ffffff;
    }
  }

  .main {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .login-form-container {
      height: 15rem;
      border-radius: 5px;
      width: 20rem;
      background-color: #ffffff;
      margin-right: 10rem;

      .login-form {
        margin-left: 1rem;
        margin-top: 2rem;
        width: 80%;

        .login-btn {
          width: 8rem;
        }
      }
    }
  }
}
</style>